<template>
  <div
    style="height: 70vh; padding-top: 10px;"
    class="centerAll"
  >
    <card
      slot="header"
      external-card="card material-card execution-group-card card-form-container"
      internal-card="material-card-content"
    >
      <div
        slot="header"
        style="flex:1; text-align: center;"
      >
        <div
          style="padding-bottom: 20px;"
        >
          <b-icon
            icon="cloud-lock-outline"
            size="is-large"
          />
          <h1 style="font-weight: 800; font-size: 180%">
            Not Authorized
          </h1>
          You are not authorized to access or modify this settings,
          please contact your administrator.
        </div>
        <button
          class="is-primary button"
          @click="devices()"
        >
          Go to devices
        </button>
      </div>
    </card>
  </div>
</template>

<script>
import card from '@/components/cross/Card.vue';

export default {
  name: 'UnauthorizedView',
  metaInfo: {
    title: 'Not Authorized',
  },
  components: {
    card,
  },
  methods: {
    devices() {
      this.$router.push(`/${this.$route.params.companyId}/devices`);
    },
  },
};
</script>
